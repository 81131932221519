import React from 'react';
import { StyledSwitch } from './styled';
import SunIcon from '../../../assets/sun-icon.svg';
import MoonIcon from '../../../assets/moon-icon.svg';

export default function Switch({ day, setDay }) {
    return (
        <div className='flex flex-column justify-content-center align-items-center'>
            <div className='flex align-items-center mb-2'>
                <SunIcon />
                <StyledSwitch>
                    <input value={day} onChange={() => setDay(!day)} type="checkbox" />
                    <span className="slider round" />
                </StyledSwitch>
                <MoonIcon />
            </div>
            <p style={{ color: '#9E9999' }}>Click me!</p>
        </div>
    );
}