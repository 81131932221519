import insights from '../images/features/insights.png';
import notes from '../images/features/notes.png';
import playlist from '../images/features/playlist.png';
import scenes from '../images/catalog-scenes.png';
import sounds from '../images/features/sounds.png';
import timer from '../images/features/timer.png';

export const features = [
    {
        title: 'Advanced Timer',
        description: 'A powerful timer with customizable pomodoro settings, tasks with time tracking and a live report for time management.',
        image: timer,
        className: 'flex justify-content-center align-items-end',
        styles: {
            background: 'linear-gradient(305.9deg, rgba(248, 214, 214, 1) -3.54%, rgba(241, 9, 9, 0.43) 104.87%)',
            height: '90%'
        }
    },
    {
        title: 'Playlists & Templates',
        description: 'Save your favorite combinations or use pre-made sets.',
        image: playlist,
        className: 'flex justify-content-center align-items-end',
        styles: {
            background: 'linear-gradient(305.9deg, #D6E1F8 -3.54%, rgba(28, 9, 241, 0.21) 104.87%)',
            height: '90%'
        }
    },
    {
        title: 'Growing catalog of scenes',
        description: 'Enjoy the full catalog of 20+ original scenes, updated monthly.\n' +
            'Premium users can also download scenes as wallpapers.',
        image: scenes,
        className: '',
        styles: {
            background: '#fff',
            height: '90%'
        }
    },
    {
        title: 'Sounds',
        description: 'Full catalog of 15+ soundscapes.',
        image: sounds,
        className: 'flex justify-content-center align-items-end',
        styles: {
            background: 'linear-gradient(305.9deg, #E1467C -3.54%, #F3A952 104.87%)',
            height: '95%'
        }
    },
    {
        title: 'Insights',
        description: 'Check back on your tasks, focus time and break time for all your timer sessions.',
        image: insights,
        className: 'flex justify-content-center align-items-center',
        styles: {
            background: 'linear-gradient(305.9deg, #D6E1F8 -3.54%, rgba(28, 9, 241, 0.21) 104.87%)',
            height: '90%'
        }
    },
    {
        title: 'Notes',
        description: 'Capture your thoughts and ideas before they slip away.',
        image: notes,
        className: 'flex justify-content-center align-items-center',
        styles: {
            background: 'linear-gradient(305.9deg, #DAF8D6 -3.54%, rgba(46, 140, 44, 0.21) 104.87%)',
            height: '90%'
        }
    },
];