import styled, { keyframes } from 'styled-components';

const scroll = keyframes`
  from {
    transform: translateX(0%)
  }
  
  to {
    transform: translateX(-50%)
  }
`;

export const Animation = styled.div`
  overflow-x: hidden;
  position: relative;
  height: 75%;
  
  &::-webkit-scrollbar {
    display: none;
  }
`;

export const ImageContainer = styled.div`
  animation: ${scroll} 70s linear infinite;
  height: 100%;
  display: flex;
  align-items: center;
  position: absolute;
  
  img {
    height: 100%;
    object-fit: scale-down;
    margin: 0 5px;
    padding: 10px 0;
  }
`;