import React, { useState, useEffect } from 'react';
import { SecondaryAnchorButton } from '../../shared/StyledButtons/styled';
import video from '../../../assets/video/land.mp4';
import { appUrl } from '../../../common/constants';

const words = ['focus', 'work', 'study', 'relax'];

export default function Hero() {
    const [index, setIndex] = useState(0);
    const [subIndex, setSubIndex] = useState(0);
    const [blink, setBlink] = useState(true);
    const [reverse, setReverse] = useState(false);

    useEffect(() => {
        if (
            subIndex === words[index].length + 1 &&
            index !== words.length &&
            !reverse
        ) {
            setReverse(true);
            return;
        }

        if (subIndex === 0 && reverse) {
            setReverse(false);
            if (index !== words.length - 1) {
                setIndex((prev) => prev + 1);
            } else {
                setIndex(0);
            }
            return;
        }

        const timeout = setTimeout(() => {
            setSubIndex((prev) => prev + (reverse ? -1 : 1));
        }, Math.max(reverse ? 75 : subIndex === words[index].length ? 1000 :
            150, parseInt(Math.random() * 350)));

        return () => clearTimeout(timeout);
    }, [subIndex, index, reverse]);

    // blinker
    useEffect(() => {
        const timeout2 = setTimeout(() => {
            setBlink((prev) => !prev);
        }, 500);
        return () => clearTimeout(timeout2);
    }, [blink]);

    return (
        <header className='flex flex-column align-items-center'>
            <h1 className='text-center text-4xl md:text-6xl font-bold mb-4 md:mb-6'>
                <span style={{ display: 'block' }}>Your calm, digital</span>
                <span style={{ display: 'block' }}>environment to</span>
                <span style={{ display: 'block', minHeight: '4rem' }}>{`${words[index].substring(0, subIndex)}${blink ? '|' : ' '}`}</span>
            </h1>
            <SecondaryAnchorButton href={appUrl} target='_blank' rel="noopener noreferrer" className='block md:hidden mb-6'>
                Get Started For Free
            </SecondaryAnchorButton>
            <video style={{ borderRadius: '20px', maxHeight: '823px', width: '840px', maxWidth: '80vw', marginBottom: '4rem' }} muted autoPlay playsInline loop>
                <source src={video} type="video/mp4" />
            </video>
        </header>
    );
}