import React, { useEffect, useRef, useState } from 'react';
import { CallToActionButton } from '../../../shared/StyledButtons/styled';
import { StyledContainer } from './styled';
import CircleButton from '../../../shared/CircleButton';
import ArrowIcon from '../../../../assets/arrow.svg';
import rain from '../../../../assets/video/rain.mp4';
import norain from '../../../../assets/video/no-rain.mp4';
import rainaudio from '../../../../assets/audio/rain-audio.mp3';
import GifVideo from '../../../shared/GifVideo';
import { appUrl } from '../../../../common/constants';

export default function CtaSound() {
    const ref = useRef();
    const [isRaining, setIsRaining] = useState(false);

    const handleVolume = (e) => {
        ref.current.volume = e.target?.value;

        const min = e.target.min;
        const max = e.target.max;
        const val = e.target.value;
        e.target.style.backgroundSize = (val - min) * 100 / (max - min) + '% 100%';
    };

    useEffect(() => {
        if (isRaining) {
            ref.current?.play();
            ref.current.volume = 0.5;
        } else {
            ref.current?.pause();
        }
    }, [isRaining]);

    return (
        <section className='flex justify-content-center mb-8'>
            <div className='grid lg:w-9'>
                <div className='text-center lg:text-left col-12 lg:col-6 flex justify-content-center'>
                    <div className='flex flex-column align-items-center lg:align-items-start justify-content-center max-w-25rem'>
                        <h3 className='text-3xl md:text-4xl font-bold mb-4'>
                            <span style={{ display: 'block' }}>Peaceful sounds to</span>
                            <span style={{ display: 'block' }}>cut out the noise</span>
                        </h3>
                        <p className='mb-4 font-helvetica font-medium text-lg'>Each scene comes with adjustable soundscapes like rain, waves or birds.</p>
                        <CallToActionButton href={appUrl} target='_blank' rel="noopener noreferrer" className='mb-4 lg:mb-0'>
                            Get Started
                        </CallToActionButton>
                    </div>
                </div>
                <div className='col-12 lg:col-6 flex'>
                    <StyledContainer>
                        <div className='absolute z-1 flex flex-column pt-8'>
                            <div className='flex justify-content-center mb-2'>
                                <CircleButton isRaining={isRaining} setIsRaining={setIsRaining} />
                            </div>
                            <div style={{
                                background: 'rgba(18, 18, 18, 0.5)',
                                padding: '6px 10px',
                                borderRadius: '5px'
                            }} className='flex flex-column align-items-center'>
                                <p className='text-white text-md'>Summer Storm</p>
                                {isRaining &&
                                    <>
                                        <audio ref={ref} loop>
                                            <source id='volume' src={rainaudio} type='audio/ogg' />
                                        </audio>
                                        <input defaultValue='0.5' type='range' id='volume' name='volume' onChange={handleVolume} step='0.1' min='0' max='1' />
                                    </>
                                }
                            </div>
                        </div>
                        <div className='absolute h-full w-auto'>
                            <div className={`${!isRaining ? 'block h-full' : 'hidden w-full'}`}>
                                <GifVideo video={norain} />
                            </div>
                            <div className={`${isRaining ? 'block h-full' : 'hidden w-full'}`}>
                                <GifVideo video={rain} />
                            </div>
                        </div>
                    </StyledContainer>
                    <div className='absolute right-0 pr-5 mt-6 hidden md:block'>
                        <ArrowIcon style={{ position: 'relative', right: '50%' }} />
                        <p className='font-bold text-primary font-helvetica' style={{ transform: 'rotate(10deg)' }}>Click the circle</p>
                    </div>
                </div>
            </div>
        </section>
    );
}