import React, { useRef, useEffect, useState } from 'react';
import { CallToActionButton } from '../../../shared/StyledButtons/styled';
import { StaticImage } from 'gatsby-plugin-image';
import { StyledContainer } from './styled';
import GifVideo from '../../../shared/GifVideo';
import { getProportion } from '../../../../common/utils';
import Switch from '../../../shared/Switch';
import kyotoparkday from '../../../../assets/video/kyotopark-day.mp4';
import kyotoparknight from '../../../../assets/video/kyotopark-night.mp4';
import exteriorday from '../../../../assets/video/exterior-day.mp4';
import exteriornight from '../../../../assets/video/exterior-night.mp4';
import lvrday from '../../../../assets/video/lvr-day.mp4';
import lvrnight from '../../../../assets/video/lvr-night.mp4';
import lofiexteriorday from '../../../../assets/video/lofiexterior-day.mp4';
import lofiexteriornight from '../../../../assets/video/lofiexterior-night.mp4';
import kyotostreetday from '../../../../assets/video/kyotostreet-day.mp4';
import kyotostreetnight from '../../../../assets/video/kyotostreet-night.mp4';
import lofiday from '../../../../assets/video/lofi-day.mp4';
import lofinight from '../../../../assets/video/lofi-night.mp4';
import { appUrl } from '../../../../common/constants';

export default function CtaScene() {
    const ref = useRef();
    const [topScenesStyle, setTopSceneStyles] = useState({ width: 280 });
    const [bottomScenesStyle, setBottomSceneStyles] = useState({ width: 300, translateX: 40 });
    const [day, setDay] = useState(true);

    const handleScroll = () => {
        const el = ref.current.getBoundingClientRect();
        const position = (el.y / el.height) * 100;
        if (el.y > 0 && el.y < el.height && el.width < 768) {
            setTopSceneStyles({ width: getProportion(position, 280, 180) });
            setBottomSceneStyles({ width: getProportion(position, 300, 200), translateX: getProportion(position, 60, 15) });
        } else if (el.y > 0 && el.y < el.height && el.width) {
            setTopSceneStyles({ width: getProportion(position, 200, 98) });
            setBottomSceneStyles({ width: getProportion(position, 230, 140), translateX: getProportion(position, 60, 10) });
        }
    };

    useEffect(() => {
        window.addEventListener('scroll', handleScroll);
        return () => window.removeEventListener('scroll', handleScroll);
    }, []);

    const styles = {
        topScenes: {
            width: `${topScenesStyle.width}vw`
        },
        bottomScenes: {
            width: `${bottomScenesStyle.width}vw`,
            transform: `translateX(${bottomScenesStyle.translateX}vw)`
        }
    };

    return (
        <section className='flex justify-content-center mb-8 -mx-2 md:-mx-4'>
            <div className='grid w-full justify-content-center'>
                <div className='text-center col-12 flex justify-content-center'>
                    <div className='flex max-w-30rem flex-column align-items-center'>
                        <h3 className='text-3xl md:text-4xl font-bold mb-4'>
                            <span style={{ display: 'block' }}>Craft your focus</span>
                            <span style={{ display: 'block' }}>environment</span>
                        </h3>
                        <p className='mb-4 font-helvetica font-medium text-lg'>Would you rather get it done in the cafe, or escape to the beach? lofi.co gives you both and a lot more.</p>
                        <CallToActionButton href={appUrl} target='_blank' rel="noopener noreferrer" className='mb-4'>
                            Get Started
                        </CallToActionButton>
                    </div>
                </div>
                <div className='mb-6'>
                    <Switch day={day} setDay={setDay} />
                </div>
                <StyledContainer ref={ref} className='col-12 flex flex-column align-items-center overflow-x-hidden'>
                    <div className='grid' style={styles.topScenes}>
                        <div className='col-4'>
                            <div className={`${day ? 'block' : 'hidden'}`}>
                                <GifVideo video={kyotoparkday} />
                            </div>
                            <div className={`${!day ? 'block' : 'hidden'}`}>
                                <GifVideo video={kyotoparknight} />
                            </div>
                        </div>
                        <div className='col-4'>
                            <div className={`${day ? 'block' : 'hidden'}`}>
                                <GifVideo video={exteriorday} />
                            </div>
                            <div className={`${!day ? 'block' : 'hidden'}`}>
                                <GifVideo video={exteriornight} />
                            </div>
                        </div>
                        <div className='col-4'>
                            <div className={`${day ? 'block' : 'hidden'}`}>
                                <GifVideo video={lvrday} />
                            </div>
                            <div className={`${!day ? 'block' : 'hidden'}`}>
                                <GifVideo video={lvrnight} />
                            </div>
                        </div>
                    </div>
                    <div className='grid' style={styles.bottomScenes}>
                        <div className='col-3'>
                            <div className={`${day ? 'block' : 'hidden'}`}>
                                <GifVideo video={lofiexteriorday} />
                            </div>
                            <div className={`${!day ? 'block' : 'hidden'}`}>
                                <GifVideo video={lofiexteriornight} />
                            </div>
                        </div>
                        <div className='col-3'>
                            <div className={`${day ? 'block' : 'hidden'}`}>
                                <GifVideo video={kyotostreetday} />
                            </div>
                            <div className={`${!day ? 'block' : 'hidden'}`}>
                                <GifVideo video={kyotostreetnight} />
                            </div>
                        </div>
                        <div className='col-3'>
                            <div className={`${day ? 'block' : 'hidden'}`}>
                                <GifVideo video={lofiday} />
                            </div>
                            <div className={`${!day ? 'block' : 'hidden'}`}>
                                <GifVideo video={lofinight} />
                            </div>
                        </div>
                        <div className='col-3'>
                            <StaticImage src='../../../../images/scenes/scene.png' alt='scene' />
                        </div>
                    </div>
                </StyledContainer>
            </div>
        </section>
    );
}