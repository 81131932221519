import React from 'react';
import FeedbackCard from '../../shared/FeedbackCard';
import { feedbacks } from '../../../common/feedbacks';
import { Animation, FeedbackContainer } from './styled';

export default function FeedbackScroller() {
    return (
        <section className='mb-2'>
            <h2 className='text-center text-4xl md:text-5xl font-bold mb-2'>
                <span style={{ display: 'block' }}>Join +150.000 users</span>
                <span style={{ display: 'block' }}>chilling every month</span>
            </h2>
            {/*<div className='block md:hidden mb-6 flex flex-column align-items-center'>*/}
            {/*    <h2 className='text-center text-4xl font-bold mb-6'>*/}
            {/*        <span style={{ display: 'block' }}>So many others</span>*/}
            {/*        <span style={{ display: 'block' }}>already tried Lofi.co</span>*/}
            {/*    </h2>*/}
            {/*    <IconButton icon={<TwitterIcon />} label='Tweet us at @Lofi.co' />*/}
            {/*</div>*/}
            <Animation className='-mx-2 md:-mx-4'>
                <FeedbackContainer>
                    <div className='flex align-items-center'>
                        {feedbacks.map((feedback, index) => {
                            return (
                                <FeedbackCard key={index} theme='light' feedback={feedback} type='scroll' />
                            );
                        })}
                    </div>
                    <div className='flex align-items-center'>
                        {feedbacks.map((feedback, index) => {
                            return (
                                <FeedbackCard key={index} theme='light' feedback={feedback} type='scroll' />
                            );
                        })}
                    </div>
                </FeedbackContainer>
            </Animation>
        </section>
    );
}