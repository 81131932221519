import React from 'react';
import { SecondaryLinkButton } from '../../shared/StyledButtons/styled';
import { features } from '../../../common/features';
import Card from '../Card';
import AnimatedCard from '../AnimatedCard';

export default function Features() {

    return (
        <section className='flex flex-column align-items-center'>
            <h2 className='text-center text-4xl md:text-5xl font-bold mb-4'>
                <span style={{ display: 'block' }}>Get more done with</span>
                <span className='text-primary' style={{ display: 'block' }}>premium</span>
            </h2>
            <SecondaryLinkButton className="mb-8" to='/pricing'>See Pricing</SecondaryLinkButton>
            <div className='w-full lg:w-9 mb-6'>
                <div className='grid'>
                    <div className='col-12 lg:col-7'>
                        <Card feature={features[0]} />
                    </div>
                    <div className='col-12 lg:col-5'>
                        <Card feature={features[1]} />
                    </div>
                    <div className='col-12 lg:col-7'>
                        <AnimatedCard feature={features[2]} />
                    </div>
                    <div className='col-12 lg:col-5'>
                        <Card feature={features[3]} />
                    </div>
                    <div className='col-12 lg:col-5'>
                        <Card feature={features[4]} />
                    </div>
                    <div className='col-12 lg:col-7'>
                        <Card feature={features[5]} />
                    </div>
                </div>
            </div>
            <h2 className='text-center text-3xl md:text-4xl font-bold mb-5'>
                ..and <span className='text-primary'>more.</span>
            </h2>
            <SecondaryLinkButton className="mb-8" to='/pricing'>See Pricing</SecondaryLinkButton>
        </section>
    );
}