import React from 'react';
import { StyledCircleButton } from './styled';

export default function CircleButton({ isRaining, setIsRaining }) {
    return (
        <StyledCircleButton style={{
            border: isRaining ? '3px #F3A952 solid' : ''
        }} onClick={() => setIsRaining(!isRaining)}>
            <div style={{
                background: isRaining ? '#F3A952' : ''
            }} />
        </StyledCircleButton>
    );
}