import styled from 'styled-components';
import image from '../../../../images/hero-image.png';

export const StyledContainer = styled.div`
  background-image: url(${image});
  background-size: cover;
  background-repeat: no-repeat;
  aspect-ratio: 6 / 5;
  width: 100%;
  border-radius: 16px;
  display: flex;
  justify-content: center;
  align-items: flex-end;
`;