import React from 'react';
import { Animation, ImageContainer } from './styled';

export default function AnimatedCard({ feature }) {
    const { title, description, image, background } = feature;

    return (
        <div style={{
            height: '40rem',
            background: '#fff',
            borderRadius: '32px',
            padding: '2rem 2rem 5rem 2rem',
        }}>
            <div className='mb-4' style={{ height: '25%' }}>
                <h3 className='mb-3 text-2xl'>{title}</h3>
                <p className='font-bold text-lg' style={{ color: '#837E7E' }}>{description}</p>
            </div>
            <Animation style={{ background: background }}>
                <ImageContainer>
                    <img src={image} alt='feature' />
                    <img src={image} alt='feature' />
                </ImageContainer>
            </Animation>
        </div>
    );
}