import React, { useEffect, useRef, useState } from 'react';
import { CallToActionButton } from '../../../shared/StyledButtons/styled';
import { StyledContainer } from './styled';
import SleepyIcon from '../../../../assets/sleepy-icon.svg';
import JazzyIcon from '../../../../assets/jazzy-icon.svg';
import ChillIcon from '../../../../assets/chill-icon.svg';
import player from '../../../../images/player.png';
import { getProportion } from '../../../../common/utils';
import { appUrl } from '../../../../common/constants';

export default function CtaPlayer() {
    const ref = useRef();
    const [playerStyles, setPlayerStyles] = useState({ height: 63 });
    const [sleepyIconStyles, setSleepyIconStyles] = useState({ translateX: -250, rotate: -20, scale: 1.3 });
    const [chillIconStyles, setChillIconStyles] = useState({ translateX: 20, translateY: -95, rotate: 20 });
    const [jazzyIconStyles, setJazzyIconStyles] = useState({ translateX: 20, translateY: -180, rotate: 20, scale: 1.1 });

    const handleScroll = () => {
        const el = ref.current.getBoundingClientRect();
        const y = el.y - (el.height / 1.5);
        if (y > 0 && y < el.height) {
            const position = (y / el.height) * 100;

            setSleepyIconStyles({
                translateX: getProportion(position, -250, 0),
                rotate: getProportion(position, -20, 0),
                scale: getProportion(position, 1.3, 1)
            });

            setChillIconStyles({
                translateX: getProportion(position, 20, 0),
                translateY: getProportion(position, -95, 0),
                rotate: getProportion(position, 20, 0),
            });

            setJazzyIconStyles({
                translateX: getProportion(position, 20, 0),
                translateY: getProportion(position, -180, 0),
                rotate: getProportion(position, 20, 0),
                scale: getProportion(position, 1.1, 1),
            });

            setPlayerStyles({ height: getProportion(position, 63, 75) });
        }
    };

    useEffect(() => {
        window.addEventListener('scroll', handleScroll);
        return () => window.removeEventListener('scroll', handleScroll);
    }, []);

    const dynamicStyles = {
        player: {
            backgroundImage: `url(${player})`,
            backgroundSize: 'cover',
            backgroundRepeat: 'no-repeat',
            width: '50%',
            height: `${playerStyles.height}%`
        },
        iconSleepy: {
            transform: `translateY(${sleepyIconStyles.translateX}%) rotate(${sleepyIconStyles.rotate}deg) scale(${sleepyIconStyles.scale})`
        },
        iconChill: {
            transform: `translateX(${chillIconStyles.translateX}%) translateY(${chillIconStyles.translateY}%) rotate(${chillIconStyles.rotate}deg)`
        },
        iconJazzy: {
            transform: `translateX(${jazzyIconStyles.translateX}%) translateY(${jazzyIconStyles.translateY}%) rotate(${jazzyIconStyles.rotate}deg) scale(${jazzyIconStyles.scale})`
        }
    };

    return (
        <section className='flex justify-content-center mb-8'>
            <div className='grid lg:w-9'>
                <div className='text-center lg:text-left col-12 lg:col-6 flex justify-content-center'>
                    <div className='flex flex-column align-items-center lg:align-items-start justify-content-center max-w-25rem'>
                        <h3 className='text-3xl md:text-4xl font-bold mb-4'>
                            <span style={{ display: 'block' }}>The Perfect Playlist</span>
                            <span style={{ display: 'block' }}>One Click Away</span>
                        </h3>
                        <p className='mb-4 font-helvetica text-lg font-medium'>Hit play and get into the zone instantly. No ads. No distracting lyrics.</p>
                        <CallToActionButton href={appUrl} target='_blank' rel="noopener noreferrer" className='mb-4 lg:mb-0'>
                Get Started
                        </CallToActionButton>
                    </div>
                </div>
                <div className='col-12 lg:col-6' ref={ref}>
                    <StyledContainer>
                        <div style={dynamicStyles.player}>
                            <div className='grid justify-content-center align-items-center mx-1' style={{ height: '75%' }}>
                                <div className='col-4' style={dynamicStyles.iconSleepy}>
                                    <SleepyIcon />
                                </div>
                                <div className='col-4' style={dynamicStyles.iconChill}>
                                    <ChillIcon />
                                </div>
                                <div className='col-4' style={dynamicStyles.iconJazzy}>
                                    <JazzyIcon />
                                </div>
                            </div>
                        </div>
                        <div />
                    </StyledContainer>
                </div>
            </div>
        </section>
    );
}