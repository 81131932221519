import React from 'react';

export default function Card({ feature }) {
    const { title, description, image, className, styles } =feature;

    return (
        <div style={{
            height: '40rem',
            background: '#fff',
            borderRadius: '32px',
            padding: '2rem 2rem 5rem 2rem',
        }}>
            <div className='mb-4' style={{ height: '25%' }}>
                <h3 className='mb-3 text-2xl'>{title}</h3>
                <p className='font-bold font-helvetica text-lg' style={{ color: '#837E7E' }}>{description}</p>
            </div>
            <div className={className}
                style={{
                    background: styles.background,
                    borderRadius: '24px',
                    height: '75%',
                    padding: '4px 8px 0 8px'
                }}>
                <img style={{
                    maxWidth: '100%',
                    width: 'auto',
                    height: styles.height,
                    objectFit: 'scale-down'
                }} src={image} alt='feature' />
            </div>
        </div>
    );
}