import styled from 'styled-components';

export const StyledContainer = styled.div`
  aspect-ratio: 6 / 5;
  width: 100%;
  border-radius: 16px;
  overflow: hidden;
  object-fit: contain;
  position: relative;
  display: flex;
  justify-content: center;
  
  video {
    height: 100%;
    width: auto;
  }

  input[type="range"] {
    outline: none;
    margin-top: 12px;
    margin-bottom: 10px;
    -webkit-appearance: none;
    margin-right: 15px;
    width: 200px;
    height: 10px;
    background: rgba(255, 255, 255, 0.6);
    border-radius: 5px;
    background-image: linear-gradient(#F3A952, #F3A952);
    background-size: 50% 100%;
    background-repeat: no-repeat;
  }
  
  input[type="range"]::-webkit-slider-thumb {
    -webkit-appearance: none;
    height: 20px;
    width: 20px;
    border-radius: 50%;
    background: #fff;
    cursor: pointer;
    box-shadow: 0 0 2px 0 #555;
    transition: background .3s ease-in-out;
  }

  input[type="range"]::-moz-range-thumb {
    -webkit-appearance: none;
    height: 20px;
    width: 20px;
    border-radius: 50%;
    background: #fff;
    cursor: ew-resize;
    box-shadow: 0 0 2px 0 #555;
    transition: background .3s ease-in-out;
  }

  input[type="range"]::-ms-thumb {
    -webkit-appearance: none;
    height: 20px;
    width: 20px;
    border-radius: 50%;
    background: #fff;
    cursor: pointer;
    box-shadow: 0 0 2px 0 #555;
    transition: background .3s ease-in-out;
  }

  input[type="range"]::-webkit-slider-thumb:hover {
    background: #fff;
  }

  input[type="range"]::-moz-range-thumb:hover {
    background: #fff;
  }

  input[type="range"]::-ms-thumb:hover {
    background: #fff;
  }
  
  input[type=range]::-webkit-slider-runnable-track  {
    -webkit-appearance: none;
    box-shadow: none;
    border: none;
    background: transparent;
  }

  input[type=range]::-moz-range-track {
    -webkit-appearance: none;
    box-shadow: none;
    border: none;
    background: transparent;
  }

  input[type="range"]::-ms-track {
    -webkit-appearance: none;
    box-shadow: none;
    border: none;
    background: transparent;
  }
`;
