import styled from 'styled-components';

export const StyledCircleButton = styled.button`
  background: none;
  padding: 5px;
  border: 3px #fff solid;
  border-radius: 50%;
  color: inherit;
  font: inherit;
  cursor: pointer;
  outline: inherit;
  
  div {
    background: transparent;
    padding: 10px;
    border-radius: 50%;
  }
  
  &:hover {
    div {
      background: #fff;
    }
  }
`;