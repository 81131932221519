import React from 'react';

// styles
import '../styles/index.css';
import '/node_modules/primeflex/primeflex.css';

// components
import MainLayout from '../hoc/MainLayout';
import Hero from '../components/home/Hero';
import FeedbackScroller from '../components/home/FeedbackScroller';
import CtaPlayer from '../components/home/CallToAction/CtaPlayer';
import CtaScene from '../components/home/CallToAction/CtaScene';
import CtaSound from '../components/home/CallToAction/CtaSound';
import Features from '../components/home/Features';
import { loadCrispChat } from '../common/utils';
import Seo from '../components/shared/Seo';

const IndexPage = () => {
    loadCrispChat();

    return (
        <MainLayout>
            <Seo title='Lofi.co - Craft your focus environment' keywords={['music', 'focus', 'relax', 'work']} lang='en' />
            <Hero />
            <FeedbackScroller />
            <h2 className='text-center text-4xl md:text-5xl font-bold mb-8'>
                <span style={{ display: 'block' }}>Everything you need</span>
                <span style={{ display: 'block' }}>to make the most of</span>
                <span style={{ display: 'block' }}>your time</span>
            </h2>
            <CtaPlayer />
            <CtaScene />
            <CtaSound />
            <Features />
        </MainLayout>
    );
};

export default IndexPage;